import React from "react"

import SEO from "../components/SEO"
import PageWrapper from "../components/PageWrapper"
import { Card } from "react-bootstrap"
import Button from "../components/basic/Button"

const NotFoundPage = () => (
  <PageWrapper>
    <Card className="mb-4 p-5">
      <h1 className="mb-3">404: Not Found</h1>
      <p>Diese Seite existiert nicht!</p>
      <Button text="Zur Startseite" href="/" />
    </Card>
  </PageWrapper>
)

export default NotFoundPage
